import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCustomerRecover } from "../../../../hooks/useCustomer"

export const withForgotForm = Component => ({ name = "ForgotForm" }) => {
  const { recoverCustomer, data, setData, loading, success, errors } = useCustomerRecover()

  const { forgot } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_FORGOT {
      forgot: sanityPageAccountForgot {
        additionalSubmitButton
        additionalEmailPlaceholder
        additionalSuccessMessage
      }
    }
  `)

  const { additionalSubmitButton, additionalEmailPlaceholder, additionalSuccessMessage } = forgot || {}

  const handleSubmit = async event => {
    event.preventDefault()
    await recoverCustomer(data?.email)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      success={success}
      additionalSuccessMessage={additionalSuccessMessage}
      additionalSubmitButton={additionalSubmitButton}
      additionalEmailPlaceholder={additionalEmailPlaceholder}
    />
  )
}
