import React from "react"
import { graphql } from "gatsby"

import { Forgot as Page } from "../../components/Account/Forgot/Forgot"

export const query = graphql`
  query {
    page: sanityPageAccountForgot {
      title
      description
      image: _rawImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
